import logo from "./logo.webp"

const clientData = {
    client_entity: 25,
    attorney_firm: 'Bachus Schanker',
    attorney_name: 'Chris Elliott',
    attorney_number: '303-222-2222',
    attorney_email: 'CElliott@coloradolaw.net',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_explainer_-_bachus_schanker+(720p).mp4',
    mainColor: '#C8C9C7',
    secondaryColor: '#001131',
    siteLink: 'http://www.coloradolaw.net',
    logo
}

export default clientData